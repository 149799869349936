// 签到
<template>
  <div class="main">
    <div id="mapDiv"
         class="mapDiv"
         ref="mapDiv">
    </div>
    <img class="map-logo"
         src="../../assets/main/map_logo.png">
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '../../utils/tMap'
export default {
  components: {},
  data () {
    return {
      // 地图
      T: '',
      zoom: 0,
      map: '',
      location: {},
      address: '',
    };
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.createMap()
      // 方法挂到window中
      window.getCurrentLocation = this.getCurrentLocation
      window.openMapClickEvent = this.openMapClickEvent
      window.getMapMarker = this.getMapMarker
      window.getLocation = this.getLocation
    },
    createMap () {
      this.$Spin.show()
      tMap.init().then((T) => {
        this.$Spin.hide();
        this.zoom = 15
        this.map = new T.Map('mapDiv')
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.centerAndZoom(lnglat, this.zoom)
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败，请刷新浏览器')
      })
    },
    // 获取当前地址  签到
    getCurrentLocation () {
      let lo = new T.Geolocation();
      let that = this
      lo.getCurrentPosition(function (e) {
        console.log('获取当前信息的返回值:' + JSON.stringify(e));
        // 移动端
        if (this.getStatus() == 0) {
          that.map.centerAndZoom(e.lnglat, this.zoom)
          that.location = e.lnglat
          that.createMarker(e.lnglat)
        }
        // PC端
        if (this.getStatus() == 1) {
          that.map.centerAndZoom(e.lnglat, e.level)
          that.location = e.lnglat
          that.createMarker(e.lnglat)
        }
      }, { enableHighAccuracy: true });
    },
    // 获取地图标记点  案件详情显示图标
    getMapMarker (objJson) {
      console.log('getMapMarker传入的对象:' + objJson);
      if (!objJson) return
      let obj = JSON.parse(objJson)
      this.map.centerAndZoom(new T.LngLat(obj.lng, obj.lat), this.zoom)
      this.createMarker(obj)
    },
    // 生成标记点
    async createMarker (location) {
      //创建图片对象
      let icon = new T.Icon({
        iconUrl: require('../../assets/main/guidepost.png'),
        iconSize: new T.Point(24, 32),
        iconAnchor: new T.Point(10, 30)
      })
      let lnglat = new T.LngLat(location.lng, location.lat)
      let marker = new T.Marker(lnglat, { icon: icon })
      if (!location.address) {
        this.address = await this.getAddress(lnglat)
      } else {
        this.address = location.address
      }
      let content = `<div>${this.address}</div>`
      let label = new T.Label({ text: content, position: lnglat, offset: new T.Point(0, -40) })
      this.map.addOverLay(marker)
      this.map.addOverLay(label)
    },
    // 开启地图点击事件
    async openMapClickEvent () {
      this.map.addEventListener('click', e => {
        // 点击地区的经纬度
        this.location = e.lnglat
        // 先清空原先的覆盖物
        this.map.clearOverLays()
        this.createMarker(e.lnglat)
      })
    },
    // 逆地理编码
    async getAddress (lnglat) {
      const geocode = new T.Geocoder()
      return new Promise((resolve) => {
        geocode.getLocation(lnglat, (e) => {
          if (e.getStatus() === 0) {
            const addressComponent = e.getAddressComponent()
            resolve(addressComponent.address)
          } else {
            resolve(lnglat.lng + ',' + lnglat.lat)
          }
        })
      })
    },
    // 获取经纬度
    getLocation () {
      let obj = {
        lng: this.location.lng,
        lat: this.location.lat,
        address: this.address
      }
      console.log('getLocation返回值:' + JSON.stringify(obj));
      return obj
    }
  },
}
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  .mapDiv {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .map-logo {
    z-index: 100;
    position: absolute;
    left: 10px;
    bottom: 30px;
  }
}
::v-deep {
  .tdt-infowindow {
    .tdt-infowindow-tip-container {
      .tdt-infowindow-tip {
        background: rgba(0, 0, 0, 0.85);
      }
    }
    .tdt-infowindow-content-wrapper {
      background: rgba(0, 0, 0, 0.85);
      color: #83898e;
      border-radius: 6px;
      cursor: pointer;
      .tdt-infowindow-content {
        width: 160px;
        .poptip {
          .poptip-treename {
            margin-bottom: 10px;
          }
          .poptip-title {
            display: flex;
            justify-content: space-between;
            // padding-bottom: 10px;
            // margin-bottom: 10px;
            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .event {
              color: #fff;
            }
            span {
              margin-left: auto;
              padding: 2px 6px;
              border-radius: 10px;
              font-size: 12px;
            }
            .on {
              color: #32b16c;
              background: rgba(50, 177, 108, 0.2);
            }
            .off {
              color: #84919e;
              background: rgba(132, 145, 158, 0.2);
            }
            .task {
              color: #1890ff;
              background: rgba(24, 144, 255, 0.2);
            }
            .alert {
              color: #c30b19;
              background: rgba(195, 11, 25, 0.2);
            }
          }
          .poptip-cont {
            padding: 10px 0;
          }
          .poptip-speed {
            margin-bottom: 10px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 12px;
            color: #fff;
            display: flex;
            // justify-content: space-between;
            p {
              flex: 1;
            }
          }
          .btn-box {
            display: flex;
            justify-content: space-between;
            .item {
              flex: 1;
              background: rgba(255, 255, 255, 0.1);
              color: #eeeeee;
              border-radius: 4px;
              text-align: center;
              line-height: 32px;
              height: 32px;
              cursor: pointer;
              a {
                color: #fff;
              }
            }
            .item:last-child {
              margin-left: 8px;
            }
          }
          .area-box {
            display: flex;
            margin-bottom: 6px;
            align-items: center;
            justify-content: center;
            div {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 49%;
              p {
                margin-bottom: 8px;
              }
              span {
                color: #eee;
                font-size: 16px;
              }
            }
            div:nth-of-type(1) {
              border-right: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>